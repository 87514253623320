<script>
import Layout from "../../layouts/main";
import { cattelService } from "../../../helpers/cattel.service";
import Swal from "sweetalert2";

/**
 * Form-element component
 */
export default {
  components: { Layout },
  data() {
    return {
      CowData: cattelService
        .getCow(this.$router.currentRoute.params.id)
        .then((data) => {
          if (data.message.length == 0) {
            this.$router.push({
              path: "/404",
              redirect: { name: "Error-404" },
            });
          } else {
            this.CowData = data.message;
          }
        }),
      swalWithBootstrapButtons: Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      }),
      Events: [],
      Arraytotext: {
        id: "شناسه",
        cow_num: "شماره دام",
        cow_type: "نوع دام",
        birthtime: "تاریخ تولد",
        serial_num: "شماره سریال",
        registr_num: "شماره ثبت",
        births_num: "شیرواری",
        last_birthtime: "تاریخ آخرین زایش",
        last_inoculatedtime: "تاریخ آخرین تلقیح",
        last_drytime: "تاریخ آخرین خشکی",
        pregnancy_status: "وضعیت بارداری",
        position: "جایگاه",
        set_time: "تاریخ",
        description: "توضیحات",
        cow_id: "شناسه دام",
        inoculate_time: "تاریخ تلقیح",
        inoculate_round: "نوبت تلقیح",
        breastfeeding: "شیرواری زایش",
        visittime: "تاریخ ویزیت",
        visitresult: "نتیجه ویزیت",
        visitreason: "علت ویزیت",
        drytime: "تاریخ خشکی",
        scoretime: "تاریخ ثبت",
        movement_num: "نمره حرکتی",
        weight: "وزن",
        recorddate: "تاریخ ثبت",
        bcs: "نمره بدنی",
        entrydate: "تاریخ ورود",
        departuredate: "تاریخ خروج",
        reasonentry: "علت ورود",
        DIMday: "DIM (روز)",
        text: "نوع",
      },
    };
  },
  beforeMount() {
    cattelService
      .getEvents(this.$router.currentRoute.params.id)
      .then((data) => {
        if (data.message) {
          this.Events = data.message;
        } else {
          this.Events = [
            {
              text: "داده‌ای وجود ندارد",
              set_time: "داده‌ای وجود ندارد",
            },
          ];
        }
      });
  },
  methods: {},
};
</script>

<template>
  <Layout>
    <b-row>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs
              justified
              nav-class="nav-tabs-custom"
              content-class="p-3 text-muted"
            >
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-cow font-size-22"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">اطلاعات دام</span>
                </template>
                <b-list-group>
                  <b-list-group-item
                    v-for="(item, index) in CowData"
                    :key="index"
                    class="flex-column align-items-start"
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1">{{ Arraytotext[index] }}</h6>
                      <h6 v-if="item === true">
                        <i class="mdi mdi-check text-success font-size-20"></i>
                      </h6>
                      <h6 v-if="item === false">
                        <i class="mdi mdi-close text-danger font-size-20"></i>
                      </h6>
                      <h6 v-if="item !== true && item !== false">{{ item }}</h6>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-more font-size-20"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">رخداد ها</span>
                </template>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <b-list-group>
                          <b-list-group-item
                            v-for="(item, index) in Events"
                            :key="index"
                            class="flex-column align-items-start"
                          >
                            <div class="d-flex w-100 justify-content-between">
                              <h6 class="mb-1">
                                {{ item.text }} <sub> {{ item.else }} </sub>
                              </h6>
                              {{ item.set_time }}
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <router-link
              :to="'/cattel/' + $router.currentRoute.params.id"
              class="side-nav-link-ref"
            >
              <button class="btn btn-success col-12">ویرایش این دام</button>
            </router-link>
          </div>
        </div>
      </div>
    </b-row>
  </Layout>
</template>
<style>
.card.mb-1,
.list-group-item.flex-column.align-items-start {
  border: rgba(0, 0, 0, 0.15) 2px solid;
}
</style>